import React from "react"

import Layout from "../components/layout"
import Snippet from "../components/snippet"

const MottoPage = () => (
	<Layout
		meta={{
			title: "COVID-19",
			description: "How we handle the pandemic",
			path: "/covid",
		}}
		wide
	>
		<Snippet id="covid" />
	</Layout>
)

export default MottoPage
